import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { create } from 'domain';
import { JsonpInterceptor } from '@angular/common/http';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class BookmarkServiceService {

  bookmarkData

  constructor(private storage: Storage) { }

  private createObject(objectID, type, title, preview, link) {
    const jsonObject = {
      objectID,
      type,
      title,
      preview,
      link 
    };
    return jsonObject;
  }

  public add(objectID, type, title, preview, link) {
    const bookmark = this.createObject(objectID, type, title, preview, link)

    this.getData().then(bookmarks => {
      if (isNullOrUndefined(bookmarks)) {
        bookmarks = []
      }
      let JSONBookmarks = bookmarks    
      JSONBookmarks.push(bookmark)
      this.storage.set('Bookmarks', JSONBookmarks)
    })
  }

  public delete(objectID, type) {
    this.getData().then(bookmarks => {
      for (let index = 0; index < bookmarks.length; index++) {
        const bookmark = bookmarks[index]
        if (bookmark.objectID == objectID && bookmark.type == type) {
          bookmarks.splice(index, 1)
        }
      }
      this.storage.set('Bookmarks', bookmarks)
    })
  }

  public async getData() {
    const bookmarks = await this.storage.get('Bookmarks')   
    return bookmarks;
  }

  public async isBookmarked(objectID, type): Promise<boolean> {
    const isBookmarked = await this.getData().then(bookmarks => {
      if (! isNullOrUndefined(bookmarks)) {
        for (let index = 0; index < bookmarks.length; index++) {
          const bookmark = bookmarks[index]
          if (bookmark.objectID == objectID && bookmark.type == type) {
            return true
          } 
        }
        return false
      } else {
        return false
      }
    })
    return isBookmarked
  }
}
