import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavParamsService {

  private destn: any;
  private index: any;

  constructor() {}

  setDestn(destn, index) {
    this.destn = destn;
    this.index = index;
  }

  getDestn() {
    return this.destn;
  }
  getIndex() {
    return this.index;
  }

}
