import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { DataReaderService } from './data-reader.service';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private storage: Storage, private jsonBuilder: DataReaderService ) {}

  public async  getNotifications(): Promise<any[]> {
    const state = this.jsonBuilder.getCurrentState();
    let notificationData = []

    await this.storage.get(state).then((val) => {
      val.notifications.forEach(notification => {
        notificationData.push(notification)   
      }); 
    })    
    return notificationData;
  }

  //retrieves the possible notification based on the users age
  public async getNotification(): Promise<any> {

    const state = this.jsonBuilder.getCurrentState();
    let notificationData = []
    let displayNotification: any

    await this.storage.get(state).then((val) => {
      val.notifications.forEach(notification => {
        notificationData.push(notification)
      });
    })    

    await this.storage.get('user').then(user => {
      const birthdate = user['dob']
      const timeDiff = Math.abs(Date.now() - Date.parse(birthdate));
      const age = Math.floor((timeDiff / (1000 * 3600 * 24))/365);

      notificationData.forEach(notification => {
        if (age == notification.age - 1) {
          displayNotification = notification
        }
      })
    })    
    return displayNotification
  }

  public async getCategoryNotification(category_id): Promise<any> {

    const state = this.jsonBuilder.getCurrentState();
    let notificationData = []
    let displayNotification: any

    await this.storage.get(state).then((val) => {
      if (! isNullOrUndefined(val.categorynotifications)) {
        val.categorynotifications.forEach(categorynotification => {
          if (categorynotification.category_id == category_id) {
            displayNotification = categorynotification
            
          }
        });
      }
    })    
    return displayNotification
  }


  

}
