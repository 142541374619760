import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { DataReaderService } from './data-reader.service';
import { DomainService } from './domain.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  matchExp
  matchExpAdvanced
  queries = []
  
  constructor(public storage: Storage,
              public jsonBuilder: DataReaderService,
              public domainService: DomainService) { }

  private createSearchObject(domain, domain_id, title, shorttitle, subcategory_id, desc, preview) {
    const searchObject = {
      domain,
      domain_id,
      title,
      shorttitle,
      subcategory_id,
      desc,
      preview,
    }
    return searchObject;
  }

  private addSearchResult(domain, title, shorttitle, domain_id, subcategory_id, desc, searchQuery) {
    //checks to see if title is already in result list

    if (! this.searchResults.some(item => item.title === title)) {

      var preview = ""

      if (title.toLowerCase().includes(searchQuery.toLowerCase())) {
          const regex = new RegExp('(' + searchQuery + ')', 'ig');
	        title = title.replace(regex, '<span style="font-weight: bold !important;" class="match">$&</span>');
      } else {
        var previews = desc.match(this.matchExpAdvanced)
        if (previews) {

          const regex = new RegExp('(' + searchQuery + ')', 'ig');
          preview = previews[0]
	        preview = preview.replace(regex, '<span style="font-weight: bold !important;" class="match">$&</span>');
          
        } else {
          preview = '...'
        }
      }
      this.searchResults.push(this.createSearchObject(domain, domain_id, title, shorttitle, subcategory_id, desc, preview))
    }
  }

  searchResults = []

  public async search(searchQuery): Promise<any[]> {

    this.searchResults = []


    this.matchExp = new RegExp(searchQuery, 'ig');
    this.matchExpAdvanced = new RegExp('((\\w|[&,.\'\"-])+ ) {0,10}((\\w|[&,.\'\"-])+' + searchQuery + '|' + searchQuery + '(\\w|[&,.\'\"-])+|(\\w|[&,.\'\"-])+' + searchQuery + '(\\w|[&,.\'\"-])+|' + searchQuery + ')( (\\w|[&,.\'\"-])+) {0,10}', 'i');
               
    await this.domainService.setDomains().then(data => {
      let domains = data
      domains.forEach(domain => {
        domain.categories.forEach(category => {
          if (category.title.toLowerCase().includes(searchQuery.toLowerCase()) || category.description.toLowerCase().includes(searchQuery.toLowerCase())) {
            this.addSearchResult(domain.title, category.title, domain.shorttitle, category.domain_id, category.id,  category.description,  searchQuery)
          } else {
            category.page.forEach(page => {
              if (page.title.includes(searchQuery) || page.description.includes(searchQuery)) {
                this.addSearchResult(domain.title, category.title, domain.shorttitle, category.domain_id, category.id, category.description,  searchQuery)
              }
            })
          }
        });
      });
    })
    return this.searchResults
  }

  public async searchgetSearchResults(searchQuery) {
    await this.search(searchQuery).then(results => {
      return this.searchResults
    })
  }
}
