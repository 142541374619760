import { Injectable, RendererFactory2, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DataReaderService } from './data-reader.service';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document, private jsonBuilder: DataReaderService, private storage: Storage) { 
    this.renderer = this.rendererFactory.createRenderer(null, null)
  }

  async checkState() : Promise<string> {
    let userState = ''
    await this.storage.get('currentState').then(async state => {
      userState = state
      if (userState == 'NT') {
        this.renderer.addClass(this.document.body, 'nt-content')
      } else {
        this.renderer.removeClass(this.document.body, 'nt-content')
      }
    })
    return userState
  }

  enableState() {
    this.renderer.removeClass(this.document.body, 'nt-content')
  }
}
